<script setup>
import { computed } from 'vue';
import { toSorted } from '@/utilities/helpers';
import { statusEnumValues } from '@/utilities/labels';

const props = defineProps({
  modelValue: {
    type: Array,
    default() {
      return [];
    },
  },
});

const emit = defineEmits(['update:modelValue']);

const rankedSkills = statusEnumValues('ActorSkillRank');

const orderedSkills = computed(() => {
  const modelValue = props.modelValue || [];
  const mapped = rankedSkills.map((e) => {
    let position = modelValue.indexOf(e.id);
    if (position === -1) {
      position = null;
    } else {
      position += 1;
    }

    return { ...e, position };
  });
  return toSorted(mapped, (a, b) => a.position - b.position);
});

const hasChosen = computed(() => (props.modelValue || []).length > 0);

function moveSkill(rankedSkillId, direction) {
  let data = orderedSkills.value.map(e => e.id);
  let pos = data.indexOf(rankedSkillId);
  if (direction < 0) {
    if (pos > 0) {
      [data[pos], data[pos - 1]] = [data[pos - 1], data[pos]];
    }
  } else if (pos < (data.length - 1)) {
    [data[pos], data[pos + 1]] = [data[pos + 1], data[pos]];
  }
  emit('update:modelValue', data);
}

function chooseFirstSkill(rankedSkillId) {
  let data = orderedSkills.value.map(e => e.id);
  let pos = data.indexOf(rankedSkillId);
  data.splice(pos, 1);
  data.unshift(rankedSkillId);
  emit('update:modelValue', data);
}

</script>

<template lang="pug">
TransitionGroup(name="reorder")
  .level.is-mobile(v-for="skill in orderedSkills" :key="skill.id")
    .level-left
      .level-item(style="min-width: 8rem; justify-content: flex-start")
        .tags.has-addons.are-medium
          span.tag.is-info.is-rounded {{ skill.position || '?' }}
          span.tag.is-warning.is-light {{ skill.label }}
      .level-item
        .field.is-grouped
          .control
          .control
            .buttons.has-addons(v-if="hasChosen")
              OButton(rounded icon-right="caret-up" @click="moveSkill(skill.id, -1)")
              OButton(rounded icon-right="caret-down" @click="moveSkill(skill.id, 1)")
            .buttons.has-addons(v-else)
              OButton(rounded icon-right="check" @click="chooseFirstSkill(skill.id)")
</template>

<style scoped>
</style>
