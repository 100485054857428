<script setup>
import { resumeUrlsFor, fetchResumeUrls } from '@/utilities/actors';
import { computed } from 'vue';
import { urlExpired } from '@/utilities/helpers.js';

const props = defineProps({
  actorId: {
    type: String,
    default: null,
  },
  size: {
    type: String,
    default: undefined,
  },
  emptyMessage: {
    type: String,
    default: undefined,
  },
  deletable: Boolean,
});

const emit = defineEmits(['delete']);

const urls = computed(() => resumeUrlsFor(props.actorId));

const url = computed(() => {
  return urls.value?.find(r => r.style === 'original');
});

const hasResume = computed(() => url.value);

// methods

async function openResume() {
  const tab = window.open('about:blank', 'resumeTab');
  if (urlExpired(url.value)) {
    await fetchResumeUrls(props.actorId);
  }
  if (!urlExpired(url.value)) {
    tab.open(url.value.url, 'resumeTab');
  } else {
    tab.close();
  }
}

function deleteResume() {
  emit('delete', props.actorId);
}

</script>

<template lang="pug">
template(v-if="hasResume")
  span.inline-addons
    OButton(icon-right="arrow-up-right-from-square" :size="size" @click="openResume") View resume
    OButton(
      v-if="deletable"
      v-tooltip="'Delete the resume'"
      icon-right="trash"
      variant="danger"
      :size="size"
      outlined
      @click="deleteResume"
    )
template(v-else-if="emptyMessage")
  span {{ emptyMessage }}
</template>

<style scoped lang="scss">
.inline-addons {
  button:not(:last-child) {
    margin-right: 0 !important;
  }

  button:last-child {
    margin-right: 0.5rem;
  }
}
</style>
